.banner {
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor: default;
  overflow-wrap: break-word;
}
.banner .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
.banner .page-header {
  color: #fff;
  max-width: calc(960px - 6rem);
  width: 80%;
}
@media (max-width: 575px) {
  .banner .page-header,
  .banner .page-header .iconfont {
    font-size: 0.9rem;
  }
}
.banner[parallax="true"] {
  will-change: transform;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}
@media (max-width: 100vh) {
  header {
    max-height: 100vw;
  }
  #board {
    margin-top: -1rem !important;
  }
}
@media (max-width: 79.99vh) {
  .scroll-down-bar {
    display: none;
  }
}
#board {
  position: relative;
  margin-top: -2rem;
  background-color: var(--board-bg-color);
  transition: background-color 0.2s ease-in-out;
  border-radius: 0.5rem;
  z-index: 3;
  -webkit-box-shadow: 0 12px 15px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  box-shadow: 0 12px 15px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
.copy-btn {
  display: inline-block;
  cursor: pointer;
  border-radius: 0.1rem;
  border: none;
  background-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  font-size: 0.75rem;
  line-height: 1;
  font-weight: bold;
  outline: none;
  -webkit-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  padding: 0.15rem;
  position: absolute;
  right: 0.35rem;
  top: 0.15rem;
  opacity: 0;
}
.copy-btn > i {
  font-size: 0.75rem;
  font-weight: 400;
}
.copy-btn > span {
  margin-left: 5px;
}
.copy-btn-dark {
  color: #6a737d;
}
.copy-btn-light {
  color: #bababa;
}
.markdown-body pre:hover > .copy-btn {
  opacity: 0.9;
}
.markdown-body pre:hover > .copy-btn,
.markdown-body pre:not(:hover) > .copy-btn {
  outline: none;
}
footer > div:not(:first-child) {
  margin: 0.25rem 0;
  font-size: 0.85rem;
}
footer .statistics {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
footer .statistics > span {
  flex: 1;
  margin: 0 0.25rem;
}
footer .statistics > *:nth-last-child(2):first-child {
  text-align: right;
}
footer .statistics > *:nth-last-child(2):first-child ~ * {
  text-align: left;
}
footer .beian {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
footer .beian > * {
  flex: 1;
  margin: 0 0.25rem;
}
footer .beian > *:nth-last-child(2):first-child {
  text-align: right;
}
footer .beian > *:nth-last-child(2):first-child ~ * {
  text-align: left;
}
footer .beian-police {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: left;
}
footer .beian-police img {
  margin-right: 3px;
  width: 1rem;
  height: 1rem;
  margin-bottom: 0.1rem;
}
@media (max-width: 424px) {
  footer .statistics {
    flex-direction: column;
  }
  footer .statistics > *:nth-last-child(2):first-child {
    text-align: center;
  }
  footer .statistics > *:nth-last-child(2):first-child ~ * {
    text-align: center;
  }
  footer .beian {
    flex-direction: column;
  }
  footer .beian .beian-police {
    justify-content: center;
  }
  footer .beian > *:nth-last-child(2):first-child {
    text-align: center;
  }
  footer .beian > *:nth-last-child(2):first-child ~ * {
    text-align: center;
  }
}
sup > a::before,
.footnote-text::before {
  display: block;
  content: "";
  margin-top: -5rem;
  height: 5rem;
  width: 1px;
  visibility: hidden;
}
sup > a::before,
.footnote-text::before {
  display: inline-block;
}
.footnote-item::before {
  display: block;
  content: "";
  margin-top: -5rem;
  height: 5rem;
  width: 1px;
  visibility: hidden;
}
.footnote-list ol {
  list-style-type: none;
  counter-reset: sectioncounter;
  padding-left: 0.5rem;
  font-size: 0.95rem;
}
.footnote-list ol li:before {
  font-family: "Helvetica Neue", monospace, "Monaco";
  content: "[" counter(sectioncounter) "]";
  counter-increment: sectioncounter;
}
.footnote-list ol li+li {
  margin-top: 0.5rem;
}
.footnote-text {
  padding-left: 0.5em;
}
@media (max-width: 767px) {
  #subtitle {
    font-size: 1.5rem;
  }
}
@media (max-width: 575px) {
  #subtitle {
    font-size: 1.35rem;
  }
}
.qr-trigger {
  cursor: pointer;
  position: relative;
}
.qr-trigger:hover .qr-img {
  display: block;
  transition: all 0.3s;
}
.qr-img {
  max-width: 12rem;
  position: absolute;
  right: -5.25rem;
  z-index: 99;
  display: none;
  box-shadow: 0 0 20px -5px rgba(158,158,158,0.2);
  border-radius: 0.2rem;
}
.scroll-down-bar {
  position: absolute;
  width: 100%;
  height: 6rem;
  text-align: center;
  cursor: pointer;
  bottom: 0;
}
.scroll-down-bar i {
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  position: relative;
  padding-top: 2rem;
  color: #fff;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: scroll-down 1.5s infinite;
  animation: scroll-down 1.5s infinite;
}
#scroll-top-button {
  position: fixed;
  z-index: 99;
  background: var(--board-bg-color);
  transition: background-color 0.2s ease-in-out, bottom 0.3s ease;
  border-radius: 4px;
  min-width: 40px;
  min-height: 40px;
  bottom: -60px;
  outline: none;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
#scroll-top-button i {
  font-size: 1.75rem;
  margin: auto;
  color: var(--sec-text-color);
}
#scroll-top-button:hover i,
#scroll-top-button:active i {
  -webkit-animation-name: scroll-top;
  animation-name: scroll-top;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}
#local-search-result .search-list-title {
  border-left: 3px solid #0d47a1;
}
#local-search-result .search-list-content {
  padding: 0 1.25rem;
}
#local-search-result .search-word {
  color: #ff4500;
}
html,
body {
  font-size: 16px;
  font-family: var(--font-family-sans-serif);
}
html,
body,
header {
  height: 100%;
  overflow-wrap: break-word;
}
body {
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  background-color: var(--body-bg-color);
  color: var(--text-color);
}
body a {
  color: var(--text-color);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
body a:hover {
  color: var(--link-hover-color);
  text-decoration: none;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
code {
  color: inherit;
}
img[lazyload] {
  object-fit: cover;
}
*[align="left"] {
  text-align: left;
}
*[align="center"] {
  text-align: center;
}
*[align="right"] {
  text-align: right;
}
:root {
  --color-mode: 'light';
  --body-bg-color: #eee;
  --board-bg-color: #fff;
  --text-color: #3c4858;
  --sec-text-color: #718096;
  --post-text-color: #2c3e50;
  --post-heading-color: #1a202c;
  --post-link-color: #0366d6;
  --link-hover-color: #30a9de;
  --link-hover-bg-color: #f8f9fa;
  --navbar-bg-color: #2f4154;
  --navbar-text-color: #fff;
}
@media (prefers-color-scheme: dark) {
  :root {
    --color-mode: 'dark';
  }
  :root:not([data-user-color-scheme]) {
    --body-bg-color: #181c27;
    --board-bg-color: #252d38;
    --text-color: #c4c6c9;
    --sec-text-color: #a7a9ad;
    --post-text-color: #c4c6c9;
    --post-heading-color: #c4c6c9;
    --post-link-color: #1589e9;
    --link-hover-color: #30a9de;
    --link-hover-bg-color: #364151;
    --navbar-bg-color: #1f3144;
    --navbar-text-color: #d0d0d0;
  }
  :root:not([data-user-color-scheme]) img,
  :root:not([data-user-color-scheme]) .note,
  :root:not([data-user-color-scheme]) .label {
    -webkit-filter: brightness(0.9);
    filter: brightness(0.9);
    transition: filter 0.2s ease-in-out;
  }
  :root:not([data-user-color-scheme]) .page-header {
    color: #ddd;
    transition: color 0.2s ease-in-out;
  }
  :root:not([data-user-color-scheme]) .markdown-body :not(pre) > code {
    background-color: rgba(62,75,94,0.35);
    transition: background-color 0.2s ease-in-out;
  }
  :root:not([data-user-color-scheme]) .markdown-body pre,
  :root:not([data-user-color-scheme]) .markdown-body td.gutter,
  :root:not([data-user-color-scheme]) .markdown-body td.code > .code-wrapper {
    -webkit-filter: brightness(0.9);
    filter: brightness(0.9);
    transition: filter 0.2s ease-in-out;
  }
  :root:not([data-user-color-scheme]) .markdown-body td.gutter pre {
    -webkit-filter: initial;
    filter: initial;
  }
  :root:not([data-user-color-scheme]) .markdown-body h1,
  :root:not([data-user-color-scheme]) .markdown-body h2 {
    border-bottom-color: #435266;
    transition: border-bottom-color 0.2s ease-in-out;
  }
  :root:not([data-user-color-scheme]) .markdown-body h1,
  :root:not([data-user-color-scheme]) .markdown-body h2,
  :root:not([data-user-color-scheme]) .markdown-body h3,
  :root:not([data-user-color-scheme]) .markdown-body h6,
  :root:not([data-user-color-scheme]) .markdown-body h5 {
    color: #ddd;
    transition: all 0.2s ease-in-out;
  }
  :root:not([data-user-color-scheme]) .markdown-body table tr {
    background-color: var(--board-bg-color);
    transition: background-color 0.2s ease-in-out;
  }
  :root:not([data-user-color-scheme]) .markdown-body table tr:nth-child(2n) {
    background-color: var(--board-bg-color);
    transition: background-color 0.2s ease-in-out;
  }
  :root:not([data-user-color-scheme]) .markdown-body table th,
  :root:not([data-user-color-scheme]) .markdown-body table td {
    border-color: #435266;
    transition: border-color 0.2s ease-in-out;
  }
  :root:not([data-user-color-scheme]) hr,
  :root:not([data-user-color-scheme]) .markdown-body hr {
    border-top-color: #435266;
    transition: border-top-color 0.2s ease-in-out;
  }
  :root:not([data-user-color-scheme]) .note :not(pre) > code {
    background-color: rgba(27,31,35,0.05);
  }
  :root:not([data-user-color-scheme]) .modal-dialog .modal-content .modal-header {
    border-bottom-color: #435266;
    transition: border-bottom-color 0.2s ease-in-out;
  }
  :root:not([data-user-color-scheme]) .gt-comment-admin .gt-comment-content {
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;
  }
}
[data-user-color-scheme='dark'] {
  --body-bg-color: #181c27;
  --board-bg-color: #252d38;
  --text-color: #c4c6c9;
  --sec-text-color: #a7a9ad;
  --post-text-color: #c4c6c9;
  --post-heading-color: #c4c6c9;
  --post-link-color: #1589e9;
  --link-hover-color: #30a9de;
  --link-hover-bg-color: #364151;
  --navbar-bg-color: #1f3144;
  --navbar-text-color: #d0d0d0;
}
[data-user-color-scheme='dark'] img,
[data-user-color-scheme='dark'] .note,
[data-user-color-scheme='dark'] .label {
  -webkit-filter: brightness(0.9);
  filter: brightness(0.9);
  transition: filter 0.2s ease-in-out;
}
[data-user-color-scheme='dark'] .page-header {
  color: #ddd;
  transition: color 0.2s ease-in-out;
}
[data-user-color-scheme='dark'] .markdown-body :not(pre) > code {
  background-color: rgba(62,75,94,0.35);
  transition: background-color 0.2s ease-in-out;
}
[data-user-color-scheme='dark'] .markdown-body pre,
[data-user-color-scheme='dark'] .markdown-body td.gutter,
[data-user-color-scheme='dark'] .markdown-body td.code > .code-wrapper {
  -webkit-filter: brightness(0.9);
  filter: brightness(0.9);
  transition: filter 0.2s ease-in-out;
}
[data-user-color-scheme='dark'] .markdown-body td.gutter pre {
  -webkit-filter: initial;
  filter: initial;
}
[data-user-color-scheme='dark'] .markdown-body h1,
[data-user-color-scheme='dark'] .markdown-body h2 {
  border-bottom-color: #435266;
  transition: border-bottom-color 0.2s ease-in-out;
}
[data-user-color-scheme='dark'] .markdown-body h1,
[data-user-color-scheme='dark'] .markdown-body h2,
[data-user-color-scheme='dark'] .markdown-body h3,
[data-user-color-scheme='dark'] .markdown-body h6,
[data-user-color-scheme='dark'] .markdown-body h5 {
  color: #ddd;
  transition: all 0.2s ease-in-out;
}
[data-user-color-scheme='dark'] .markdown-body table tr {
  background-color: var(--board-bg-color);
  transition: background-color 0.2s ease-in-out;
}
[data-user-color-scheme='dark'] .markdown-body table tr:nth-child(2n) {
  background-color: var(--board-bg-color);
  transition: background-color 0.2s ease-in-out;
}
[data-user-color-scheme='dark'] .markdown-body table th,
[data-user-color-scheme='dark'] .markdown-body table td {
  border-color: #435266;
  transition: border-color 0.2s ease-in-out;
}
[data-user-color-scheme='dark'] hr,
[data-user-color-scheme='dark'] .markdown-body hr {
  border-top-color: #435266;
  transition: border-top-color 0.2s ease-in-out;
}
[data-user-color-scheme='dark'] .note :not(pre) > code {
  background-color: rgba(27,31,35,0.05);
}
[data-user-color-scheme='dark'] .modal-dialog .modal-content .modal-header {
  border-bottom-color: #435266;
  transition: border-bottom-color 0.2s ease-in-out;
}
[data-user-color-scheme='dark'] .gt-comment-admin .gt-comment-content {
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
}
.fade-in-up {
  -webkit-animation-name: fade-in-up;
  animation-name: fade-in-up;
}
.hidden-mobile {
  display: block;
}
.visible-mobile {
  display: none;
}
@media (max-width: 575px) {
  .hidden-mobile {
    display: none;
  }
  .visible-mobile {
    display: block;
  }
}
.nomargin-x {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.nopadding-x {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media (max-width: 767px) {
  .nopadding-x-md {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.hover-with-bg {
  display: inline-block;
  padding: 0.45rem;
}
.hover-with-bg:hover {
  background-color: var(--link-hover-bg-color);
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  border-radius: 0.15rem;
}
@-moz-keyframes fade-in-up {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fade-in-up {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fade-in-up {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fade-in-up {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes scroll-down {
  0% {
    opacity: 0.8;
    top: 0;
  }
  50% {
    opacity: 0.4;
    top: -1em;
  }
  100% {
    opacity: 0.8;
    top: 0;
  }
}
@-webkit-keyframes scroll-down {
  0% {
    opacity: 0.8;
    top: 0;
  }
  50% {
    opacity: 0.4;
    top: -1em;
  }
  100% {
    opacity: 0.8;
    top: 0;
  }
}
@-o-keyframes scroll-down {
  0% {
    opacity: 0.8;
    top: 0;
  }
  50% {
    opacity: 0.4;
    top: -1em;
  }
  100% {
    opacity: 0.8;
    top: 0;
  }
}
@keyframes scroll-down {
  0% {
    opacity: 0.8;
    top: 0;
  }
  50% {
    opacity: 0.4;
    top: -1em;
  }
  100% {
    opacity: 0.8;
    top: 0;
  }
}
@-moz-keyframes scroll-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-0.35rem);
    transform: translateY(-0.35rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes scroll-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-0.35rem);
    transform: translateY(-0.35rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes scroll-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-0.35rem);
    transform: translateY(-0.35rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes scroll-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-0.35rem);
    transform: translateY(-0.35rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.navbar {
  background-color: transparent;
  font-size: 0.875rem;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.navbar .navbar-brand {
  color: var(--navbar-text-color);
}
.navbar .navbar-toggler .animated-icon span {
  background-color: var(--navbar-text-color);
}
.navbar .nav-item .nav-link {
  display: block;
  color: var(--navbar-text-color);
  transition: color 0.2s, background-color 0.2s;
}
.navbar .nav-item .nav-link:hover {
  color: var(--link-hover-color);
  background-color: rgba(0,0,0,0.1);
}
.navbar .nav-item .nav-link i {
  font-size: 0.875rem;
}
.navbar .navbar-toggler {
  border-width: 0;
  outline: 0;
}
.navbar.scrolling-navbar {
  will-change: background, padding;
  -webkit-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
  transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}
@media (min-width: 600px) {
  .navbar.scrolling-navbar {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .navbar.scrolling-navbar .navbar-nav > li {
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
  }
}
.navbar.scrolling-navbar.top-nav-collapse {
  padding-top: 5px;
  padding-bottom: 5px;
}
.navbar .dropdown-menu {
  font-size: 0.875rem;
  color: var(--navbar-text-color);
  background-color: rgba(0,0,0,0.3);
  border: none;
  -webkit-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
  transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}
@media (max-width: 991.98px) {
  .navbar .dropdown-menu {
    text-align: center;
  }
}
.navbar .dropdown-item {
  color: var(--navbar-text-color);
}
.navbar .dropdown-item:hover,
.navbar .dropdown-item:focus {
  color: var(--link-hover-color);
  background-color: rgba(0,0,0,0.1);
}
@media (min-width: 992px) {
  .navbar .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .navbar .dropdown > .dropdown-toggle:active {
    pointer-events: none;
  }
  .navbar .dropdown-menu {
    top: 95%;
  }
}
.navbar .animated-icon {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.navbar .animated-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  background: #fff;
}
.navbar .animated-icon span:nth-child(1) {
  top: 0;
}
.navbar .animated-icon span:nth-child(2) {
  top: 10px;
}
.navbar .animated-icon span:nth-child(3) {
  top: 20px;
}
.navbar .animated-icon.open span:nth-child(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.navbar .animated-icon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.navbar .animated-icon.open span:nth-child(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.navbar .dropdown-collapse,
.top-nav-collapse,
.navbar-col-show {
  background-color: var(--navbar-bg-color);
}
@media (max-width: 767px) {
  .navbar {
    font-size: 1rem;
    line-height: 2.5rem;
  }
}
.markdown-body {
  font-size: 1rem;
  margin-bottom: 2rem;
  font-family: var(--font-family-sans-serif);
  color: var(--post-text-color);
}
.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  color: var(--post-heading-color);
  font-weight: bold;
  margin-bottom: 0.75em;
  margin-top: 2em;
}
.markdown-body h1:focus,
.markdown-body h2:focus,
.markdown-body h3:focus,
.markdown-body h4:focus,
.markdown-body h5:focus,
.markdown-body h6:focus {
  outline: none;
}
.markdown-body a {
  color: var(--post-link-color);
}
.markdown-body hr {
  height: 0.2em;
  margin: 2rem 0;
}
.markdown-body strong {
  font-weight: bold;
}
.markdown-body pre {
  font-size: 85% !important;
}
.markdown-body pre code {
  overflow-x: auto;
  line-height: 1.5;
  border-radius: 3px;
  tab-size: 4;
}
.markdown-body pre .mermaid {
  text-align: center;
}
.markdown-body pre .mermaid > svg {
  min-width: 100%;
}
.markdown-body p > img,
.markdown-body p > a > img {
  max-width: 90%;
  margin: 1.5rem auto;
  display: block;
  box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
  border-radius: 3px;
}
.markdown-body blockquote {
  color: var(--sec-text-color);
}
.markdown-body details {
  cursor: pointer;
}
.markdown-body details summary {
  outline: none;
}
.markdown-body pre {
  border-radius: 3px;
}
.markdown-body .hljs {
  overflow-x: initial;
  padding: 0;
}
.markdown-body .hljs pre {
  background-color: initial !important;
}
.markdown-body > .code-wrapper {
  position: relative;
  border-radius: 3px;
}
.markdown-body > .code-wrapper pre[class*=language-].line-numbers {
  position: initial;
}
.markdown-body figure.highlight td.code > pre {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.markdown-body hr {
  height: 0;
  margin: 2rem 0;
  background-color: initial;
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: rgba(0,0,0,0.1);
}
figure.highlight {
  position: relative;
}
figure.highlight table {
  border: 0;
  margin: 0;
  width: auto;
  border-radius: 3px;
}
figure.highlight td {
  border: 0;
  padding: 0;
}
figure.highlight tr {
  border: 0;
}
figure.highlight td.code {
  width: 100%;
}
figure.highlight td.gutter {
  display: table-cell;
}
figure.highlight td.gutter:not(.hljs) {
  background-color: #f6f8fa;
}
figure.highlight td.gutter pre {
  text-align: right;
  font-size: 1px;
  overflow-x: none;
  overflow-y: none;
  padding: 0 0.75rem;
  line-height: 1.5;
  background-color: inherit;
  border-radius: initial;
  border-right: 1px solid #999;
}
figure.highlight td.gutter pre span.line {
  color: #999;
}
.list-group-item {
  background-color: transparent;
  border: 0;
}
.page-link {
  font-size: 1.1rem;
}
.pagination {
  margin-top: 3rem;
  justify-content: center;
}
.pagination .space {
  align-self: flex-end;
}
.pagination a,
.pagination .current {
  outline: 0;
  border: 0;
  background-color: transparent;
  font-size: 0.9rem;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  border-radius: 0.125rem;
  transition: background-color 0.2s ease-in-out;
}
.pagination a:hover,
.pagination .current {
  background-color: var(--link-hover-bg-color);
}
.modal-dialog .modal-content {
  background-color: var(--board-bg-color);
  border: 0;
  border-radius: 0.125rem;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
  box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
}
.close {
  color: var(--text-color);
}
.close:hover {
  color: var(--link-hover-color);
}
.close:focus {
  outline: 0;
}
.modal-dialog .modal-content .modal-header {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
  border-bottom: 1px solid #dee2e6;
}
.md-form {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.md-form input[type] {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.md-form input[type]:focus:not([readonly]) {
  border-bottom: 1px solid #4285f4;
  -webkit-box-shadow: 0 1px 0 0 #4285f4;
  box-shadow: 0 1px 0 0 #4285f4;
}
.md-form input[type]:focus:not([readonly]) + label {
  color: #4285f4;
}
.md-form input[type].valid,
.md-form input[type]:focus.valid {
  border-bottom: 1px solid #00c851;
  -webkit-box-shadow: 0 1px 0 0 #00c851;
  box-shadow: 0 1px 0 0 #00c851;
}
.md-form input[type].valid + label,
.md-form input[type]:focus.valid + label {
  color: #00c851;
}
.md-form input[type].invalid,
.md-form input[type]:focus.invalid {
  border-bottom: 1px solid #f44336;
  -webkit-box-shadow: 0 1px 0 0 #f44336;
  box-shadow: 0 1px 0 0 #f44336;
}
.md-form input[type].invalid + label,
.md-form input[type]:focus.invalid + label {
  color: #f44336;
}
.md-form input[type].validate {
  margin-bottom: 2.5rem;
}
.md-form input[type].form-control {
  height: auto;
  padding: 0.6rem 0 0.4rem 0;
  margin: 0 0 0.5rem 0;
  color: var(--text-color);
  background-color: transparent;
  border-radius: 0;
}
.md-form label {
  font-size: 0.8rem;
  position: absolute;
  top: -1rem;
  left: 0;
  color: #757575;
  cursor: text;
  -webkit-transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, color 0.2s ease-out, -webkit-transform 0.2s ease-out;
}
.toc-list-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.iconfont {
  font-size: 1rem;
  line-height: 1;
}
input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  transition: 0.2s;
  color: #fff;
  cursor: pointer;
  margin: 0.4rem 0.2rem 0.4rem !important;
  outline: 0;
  border-radius: 0.1875rem;
  vertical-align: -0.65rem;
  border: 2px solid #2f4154;
}
input[type=checkbox]:after,
input[type=checkbox]:before {
  content: " ";
  transition: 0.2s;
  position: absolute;
  background: #fff;
}
input[type=checkbox]:before {
  left: 0.125rem;
  top: 0.375rem;
  width: 0;
  height: 0.125rem;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
input[type=checkbox]:after {
  right: 0.5625rem;
  bottom: 0.1875rem;
  width: 0.125rem;
  height: 0;
  -webkit-transform: rotate(40deg);
  -moz-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  -o-transform: rotate(40deg);
  transform: rotate(40deg);
  transition-delay: 0.2s;
}
input[type=checkbox]:checked {
  background: #2f4154;
  margin-right: 0.5rem !important;
}
input[type=checkbox]:checked:before {
  left: 0.0625rem;
  top: 0.625rem;
  width: 0.375rem;
  height: 0.125rem;
}
input[type=checkbox]:checked:after {
  right: 0.3125rem;
  bottom: 0.0625rem;
  width: 0.125rem;
  height: 0.875rem;
}
.list-group-item-action {
  color: var(--text-color);
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  color: var(--link-hover-color);
  background-color: var(--link-hover-bg-color);
}
a.fancybox:hover {
  text-decoration: none;
}
#nprogress .bar {
  height: 3px !important;
  background-color: #29d !important;
}
#nprogress .peg {
  box-shadow: 0 0 14px #29d, 0 0 8px #29d !important;
}
@media (max-width: 575px) {
  #nprogress .bar {
    display: none;
  }
}
.v[data-class=v] .status-bar,
.v[data-class=v] .veditor,
.v[data-class=v] .vinput,
.v[data-class=v] .vbtn,
.v[data-class=v] p,
.v[data-class=v] pre code {
  color: var(--text-color) !important;
}
.v[data-class=v] .vicon {
  fill: var(--text-color) !important;
}
.gt-container .gt-comment-content:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.gt-container .gt-comment-body {
  color: var(--text-color) !important;
  transition: color 0.2s ease-in-out;
}
#remark-km423lmfdslkm34-back {
  z-index: 1030;
}
#remark-km423lmfdslkm34-node {
  z-index: 1031;
}
mjx-container,
.mjx-container {
  overflow-x: auto;
  overflow-y: hidden !important;
  padding: 0.5em 0;
}
mjx-container:focus,
.mjx-container:focus,
mjx-container svg:focus,
.mjx-container svg:focus {
  outline: none;
}
.mjx-char {
  line-height: 1;
}
.katex-block {
  overflow-x: auto;
}
.katex,
.mjx-mrow {
  white-space: pre-wrap !important;
}
.footnote-ref [class*=hint--][aria-label]:after {
  max-width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.index-card {
  margin-bottom: 2.5rem;
}
.index-img img {
  display: block;
  width: 100%;
  height: 10rem;
  object-fit: cover;
  box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
  border-radius: 0.25rem;
}
.index-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.index-header {
  color: var(--text-color);
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.25rem;
}
.index-header .index-pin {
  color: var(--text-color);
  font-size: 1.5rem;
  margin-right: 0.15rem;
}
.index-btm {
  color: var(--sec-text-color);
}
.index-btm a {
  color: var(--sec-text-color);
}
.index-excerpt {
  color: var(--sec-text-color);
  margin: 0.5rem 0 0.5rem 0;
  max-height: calc(1.4rem * 3);
  line-height: 1.4;
  overflow: hidden;
}
.index-excerpt > div {
  float: right;
  margin-left: -0.25rem;
  width: 100%;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
@media (max-width: 767px) {
  .index-info {
    padding-top: 1.25rem;
  }
  .index-header {
    font-size: 1.25rem;
    white-space: normal;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .index-header .index-pin {
    font-size: 1.25rem;
  }
}
.post-content,
post-custom {
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
}
@media (max-width: 767px) {
  .post-content,
  post-custom {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 424px) {
  .post-content,
  post-custom {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.post-content h1::before,
.post-content h2::before,
.post-content h3::before,
.post-content h4::before,
.post-content h5::before,
.post-content h6::before {
  display: block;
  content: "";
  margin-top: -5rem;
  height: 5rem;
  width: 1px;
  visibility: hidden;
}
.page-content strong,
.post-content strong {
  font-weight: bold;
}
.page-content > *:first-child,
.post-content > *:first-child {
  margin-top: 0;
}
.page-content img,
.post-content img {
  object-fit: cover;
  max-width: 100%;
}
.post-metas {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.post-meta > i {
  margin-right: 0.15rem;
}
.post-meta > a:not(.hover-with-bg) {
  margin-right: 0.15rem;
}
.post-prevnext {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.post-prevnext .post-prev,
.post-prevnext .post-next {
  display: flex;
  padding-left: 0;
  padding-right: 0;
}
.post-prevnext .post-prev i,
.post-prevnext .post-next i {
  font-size: 1.5rem;
}
.post-prevnext .post-prev a,
.post-prevnext .post-next a {
  display: flex;
  align-items: center;
}
.post-prevnext .post-prev .hidden-mobile,
.post-prevnext .post-next .hidden-mobile {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 575px) {
  .post-prevnext .post-prev .hidden-mobile,
  .post-prevnext .post-next .hidden-mobile {
    display: none;
  }
}
.post-prevnext .post-prev:hover i,
.post-prevnext .post-prev:active i,
.post-prevnext .post-next:hover i,
.post-prevnext .post-next:active i {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}
.post-prevnext .post-prev:hover i,
.post-prevnext .post-prev:active i {
  -webkit-animation-name: post-prev-anim;
  animation-name: post-prev-anim;
}
.post-prevnext .post-next:hover i,
.post-prevnext .post-next:active i {
  -webkit-animation-name: post-next-anim;
  animation-name: post-next-anim;
}
.post-prevnext .post-next {
  justify-content: flex-end;
}
.post-prevnext .fa-chevron-left {
  margin-right: 0.5rem;
}
.post-prevnext .fa-chevron-right {
  margin-left: 0.5rem;
}
#toc {
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
  padding: 3rem 0 0 0;
  visibility: hidden;
}
.toc-header {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.toc-header,
.toc-header > i {
  font-size: 1.25rem;
}
.toc-body {
  max-height: 75vh;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.toc-body ol {
  list-style: none;
  padding-inline-start: 1rem;
}
.toc-body::-webkit-scrollbar {
  display: none;
}
.tocbot-list ol {
  list-style: none;
  padding-left: 1rem;
}
.tocbot-list a {
  font-size: 0.95rem;
}
.tocbot-link {
  color: var(--text-color);
}
.tocbot-active-link {
  font-weight: bold;
  color: var(--link-hover-color);
}
.tocbot-is-collapsed {
  max-height: 0;
}
.tocbot-is-collapsible {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .toc-container {
    padding-left: 0;
    padding-right: 0;
  }
}
.markdown-body .image-caption {
  font-size: 0.8rem;
  color: var(--post-text-color);
  opacity: 0.65;
  line-height: 1;
  margin: -0.75rem auto 2rem;
  text-align: center;
}
.custom,
.comments {
  margin-top: 2rem;
}
.visitors {
  font-size: 0.8em;
  padding: 0.45rem;
  float: right;
}
@-moz-keyframes post-prev-anim {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-0.35rem);
    transform: translateX(-0.35rem);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes post-prev-anim {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-0.35rem);
    transform: translateX(-0.35rem);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes post-prev-anim {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-0.35rem);
    transform: translateX(-0.35rem);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes post-prev-anim {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-0.35rem);
    transform: translateX(-0.35rem);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes post-next-anim {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(0.35rem);
    transform: translateX(0.35rem);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes post-next-anim {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(0.35rem);
    transform: translateX(0.35rem);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes post-next-anim {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(0.35rem);
    transform: translateX(0.35rem);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes post-next-anim {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(0.35rem);
    transform: translateX(0.35rem);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.note {
  padding: 0.75rem;
  border-left: 0.35rem solid;
  border-radius: 0.25rem;
  margin: 1.5rem 0;
  color: #3c4858;
  font-size: 0.9rem;
}
.note a {
  color: #3c4858;
}
.note *:last-child {
  margin-bottom: 0;
}
.note-primary {
  background-color: #f5f0fa;
  border-color: #6f42c1;
}
.note-secondary,
note-default {
  background-color: #f7f7f7;
  border-color: #777;
}
.note-success {
  background-color: #eff8f0;
  border-color: #5cb85c;
}
.note-danger {
  background-color: #fcf1f2;
  border-color: #d9534f;
}
.note-warning {
  background-color: #fdf8ea;
  border-color: #f0ad4e;
}
.note-info {
  background-color: #eef7fa;
  border-color: #428bca;
}
.note-light {
  background-color: #fefefe;
  border-color: #0f0f0f;
}
.label {
  display: inline;
  border-radius: 3px;
  font-size: 85%;
  margin: 0;
  padding: 0.2em 0.4em;
  color: #3c4858;
}
.label-default {
  background: #f7f7f7;
}
.label-primary {
  background: #f5f0fa;
}
.label-info {
  background: #eef7fa;
}
.label-success {
  background: #eff8f0;
}
.label-warning {
  background: #fdf8ea;
}
.label-danger {
  background: #fcf1f2;
}
.markdown-body .btn {
  background: #2f4154;
  border-radius: 0.25rem;
  color: #fff !important;
  display: inline-block;
  font-size: 0.875em;
  line-height: 2;
  padding: 0 0.75rem;
  text-decoration: none;
  transition-property: background;
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  margin-bottom: 1rem;
}
.markdown-body .btn:hover {
  background: #23ae92;
  color: #fff !important;
  text-decoration: none;
}
.group-image-container {
  margin: 1.5rem auto;
}
.group-image-container img {
  margin: 0 auto;
  border-radius: 3px;
  box-shadow: 0 3px 9px 0 rgba(0,0,0,0.15), 0 3px 9px 0 rgba(0,0,0,0.15);
}
.group-image-row {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
}
.group-image-wrap {
  flex: 1;
  display: flex;
  justify-content: center;
}
.group-image-wrap:not(:last-child) {
  margin-right: 0.25rem;
}
.list-group a ~ p.h5 {
  margin-top: 1rem;
}
.about-avatar {
  position: relative;
  margin: -8rem auto 1rem;
  width: 10rem;
  height: 10rem;
  z-index: 3;
}
.about-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  object-fit: cover;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.about-info > div {
  margin-bottom: 0.5rem;
}
.about-name {
  font-size: 1.75rem;
  font-weight: bold;
}
.about-intro {
  font-size: 1rem;
}
.about-icons > a:not(:last-child) {
  margin-right: 0.5rem;
}
.about-icons > a > i {
  font-size: 1.5rem;
}
.category:not(:last-child) {
  margin-bottom: 1rem;
}
.category-item {
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.category-subitem {
  font-size: 1rem;
  font-weight: bold;
}
.category-collapse {
  margin-left: 1.25rem;
  width: 100%;
}
.category-count {
  font-size: 0.9rem;
  font-weight: initial;
  min-width: 1.3em;
  line-height: 1.3em;
  display: flex;
  align-items: center;
}
.category-count i {
  padding-right: 0.25rem;
}
.category-count span {
  width: 2rem;
}
.category-item-action:not(.collapsed) > i {
  transform: rotate(90deg);
  transform-origin: center center;
}
.category-item-action i {
  transition: transform 0.3s ease-out;
  display: inline-block;
  margin-left: 0.25rem;
}
.category-item-action:hover {
  z-index: 1;
  color: var(--link-hover-color);
  text-decoration: none;
  background-color: var(--link-hover-bg-color);
}
.category .row {
  margin-left: 0;
  margin-right: 0;
}
.tagcloud {
  padding: 1rem 5%;
}
.tagcloud a {
  display: inline-block;
  padding: 0.5rem;
}
.tagcloud a:hover {
  color: var(--link-hover-color) !important;
}
.links .card {
  box-shadow: none;
  min-width: 33%;
  background-color: transparent;
  border: 0;
}
.links .card-body {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 0.3rem;
  display: block;
  width: 100%;
  height: 100%;
}
.links .card-body:hover .link-avatar {
  transform: scale(1.1);
}
.links .card-content {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 3.5rem;
}
.link-avatar {
  flex: none;
  width: 3rem;
  height: 3rem;
  margin-right: 0.75rem;
  object-fit: cover;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
.link-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  object-fit: cover;
}
.link-text {
  flex: 1;
  display: grid;
  flex-direction: column;
}
.link-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $text_color;
  font-weight: bold;
}
.link-intro {
  max-height: 2rem;
  font-size: 0.85rem;
  line-height: 1.2;
  color: var(--sec-text-color);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 767px) {
  .links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .links .card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 768px) {
  .link-text:only-child {
    margin-left: 1rem;
  }
}
